import React from "react"

const AboutPage = () => (
  <>
    <h1>About</h1>
    <p>
      My name is Thaís Watanabe and I'm a half-japanese brazilian who likes to
      build things.
    </p>

    <h2>Work Experience</h2>
    <h3>Full-Stack Developer</h3>
    <h6>E-WIND • Jul. 2018 - present • Remote: Nagasaki/São Paulo</h6>
    <p>Managing systems for wind turbine monitoring and maintenance.</p>
    <p>
      <code>Serverless</code>
      <code>AWS</code> <code>Amplify</code>
      <code>Android</code>
      <code>Java</code> <code>Kotlin</code>
      <code>React</code>
      <code>GraphQL</code> <code>JavaScript</code>
    </p>
    <h3>Software Engineer</h3>
    <h6>Software Express • Dec. 2017 - Apr. 2018 • São Paulo - Brazil</h6>
    <p>Managing systems for electronic funds transfer.</p>
    <p>
      <code>Java</code>
      <code>Spring</code>
      <code>Hibernate</code>
      <code>JSF</code>
      <code>PrimeFaces</code>
      <code>JavaScript</code>
      <code>Oracle Rest</code>
    </p>
    <h3>Software Engineer</h3>
    <h6>Nextel • Aug. 2014 - Apr. 2017 • São Paulo - Brazil</h6>
    <p>
      Managing systems for antennas management, tickets management and
      engineering budget for the Engineering and Quality of Services team.
    </p>
    <p>
      <code>Java</code>
      <code>Spring</code>
      <code>Hibernate</code>
      <code>JSF</code>
      <code>PrimeFaces</code>
      <code>JavaScript</code>
      <code>Oracle</code>
    </p>

    <h2>Education</h2>
    <h3>JICA Nikkei Training Program - Internet of Things (IoT)</h3>
    <h6>
      Kanazawa Institute of Technology • May. 2017 - Oct. 2017 • Ishikawa -
      Japan
    </h6>
    <p>Awarded to a JICA training program scholarship in Japan.</p>
    <p>
      <ul>
        <li>
          Kawanami Laboratory: Development of a system that identifies the
          product the user is pointing at on a display and displays their
          information on a projection using Internet of Things.
          <br />
          <code>Arduino</code>
          <code>ESP</code>
          <code>Node.js</code>
          <code>MongoDB</code>
          <code>Socket.io</code>
        </li>
        <li>
          Miyazato Laboratory: Development of a system that monitors the amount
          of corrosion in steel bars of reinforced concrete using Internet of
          Things. <br />
          <code>Civil Engineering</code>
          <code>Arduino</code>
          <code>ESP</code>
          <code>ThingSpeak</code>
        </li>
      </ul>
    </p>
    <h3>MBA in Solutions Architecture</h3>
    <h6>FIAP • Oct. 2015 - Oct. 2016 • São Paulo - Brazil</h6>
    <p>
      Final project: Startup Omnipay - a solution to save time shopping avoiding
      lines using your smartphone.
    </p>
    <p>
      <code>TOGAF</code>
      <code>PMI®</code>
      <code>BPM</code>
      <code>UX</code>
      <code>Big Data/Analytics/DW</code>
      <code>SOA</code>
      <code>Agile</code>
      <code>Cloud</code>
      <code>Entrepreneurship</code>
    </p>
    <h3>AD in System Analysis and Development</h3>
    <h6>FIAP • Feb. 2012 - Dec. 2013 • São Paulo - Brazil</h6>
    <p>Final project: Development of a mobile app for food delivery.</p>
    <p>
      <code>Software Engineering</code>
      <code>OOP</code>
      <code>DB</code>
      <code>Java</code>
      <code>Mobile Development</code>
    </p>

    <h2>Honours & Awards</h2>
    <h3>Awwwards</h3>
    <h6>2021</h6>
    <p>
      <ul>
      <li><a href="https://annual.awwwards.com/independent-nominees/watanabethais">Independent of The Year Nominee</a></li>
        <li><a href="https://annual.awwwards.com/developer-nominees/isabel-moranta">Developer Site of The Year Nominee for Isabel Moranta</a></li>
        <li><a href="https://www.awwwards.com/sites/isabel-moranta">Developer Award for Isabel Moranta</a></li>
        <li><a href="https://www.awwwards.com/sites/isabel-moranta">Site of The Day for Isabel Moranta</a></li>
      </ul>
    </p>

    <h3>CSS Design Awards</h3>
    <h6>2021</h6>
    <p>
      <ul>
        <li><a href="https://www.cssdesignawards.com/sites/isabel-moranta/39295/">Site of The Day for Isabel Moranta</a></li>
      </ul>
    </p>

    <h3>TOP 10 - Startup One</h3>
    <h6>FIAP • 2016</h6>
    <p>
      Finalist of the startup competition (MBA's final project) with project
      Omnipay - save time shopping avoiding lines using your smartphone.
    </p>

    <h3>MBA Degree Scholarship</h3>
    <h6>FIAP • 2013</h6>
    <p>
      Awarded to a Master of Business Administration degree scholarship program
      for academic merit.
    </p>

    <h2>Publications</h2>
    <h3>
      <a href="http://www.union-services.com/istr/jgtr%20data/3_25.pdf">
        An Indicated Area Prediction System for Exhibitions
      </a>
    </h3>
    <h6>International Society for Tourism Research • 2018</h6>
    <p>
      JICA Nikkei Training Program - Internet of Things (IoT) research paper.
    </p>

    <h2>Certifications</h2>
    <h3>
      <a href="https://www.credly.com/badges/a1055d05-bf52-4898-ad93-4681b9235c52?source=linked_in_profile">
      AWS Certified Cloud Practitioner
      </a>
    </h3>
    <h6>Amazon Web Services</h6>
    <p>
      08/2021~08/2024
    </p>

    <h2>Languages</h2>
    <p>
      <ul>
        <li>
          Portuguese (native)
          <br />
          ⚫️⚫️⚫️⚫️⚫️
        </li>
        <li>
          English (advanced)
          <br />
          ⚫️⚫️⚫️⚫️
        </li>
        <li>
          Japanese (advanced)
          <br />
          ⚫️⚫️⚫️
          <br />
          JLPT Japanese-Language Proficiency Test - N3
        </li>
      </ul>
    </p>

    <h2>Reach Out</h2>
    <p>
      You can reach me out by this{" "}
      <a href="mailto:watanabe@e-wind.dev">e-mail</a> :)
    </p>
    <p>
      <a href="https://twitter.com/watanabethata">Twitter</a> ・
      <a href="https://www.instagram.com/watanabethais/">Instagram</a> ・
      <a href="https://github.com/watanabethais">GitHub</a> ・
      <a href="https://www.linkedin.com/in/watanabethais/">LinkedIn</a>
    </p>
  </>
)

export default AboutPage
